import type { EmployerOffer } from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";

import {
  AcceptedStatusBox,
  CanceledStatusBox,
  ExpiredStatusBox,
  PaymentInProgressStatusBox,
  PaymentMethodFailedStatusBox,
  PendingStatusBox,
  RejectedStatusBox,
  WaitingForMethodVerificationStatusBox,
} from "./status-box";

type EmployerOfferStatusProps = {
  offer: EmployerOffer;
};

export const EmployerOfferPreviewStatus = ({
  offer,
}: EmployerOfferStatusProps) => {
  switch (offer.status) {
    case ENUMS.OfferStatus.ACCEPTED:
    case ENUMS.OfferStatus.EXTENDED:
      return <AcceptedStatusBox offer={offer} />;
    case ENUMS.OfferStatus.REJECTED:
      return <RejectedStatusBox offer={offer} />;
    case ENUMS.OfferStatus.CANCELLED:
      return <CanceledStatusBox offer={offer} />;
    case ENUMS.OfferStatus.EXPIRED:
      return <ExpiredStatusBox offer={offer} />;
    case ENUMS.OfferStatus.NEW:
    case ENUMS.OfferStatus.UPDATED:
      return <PendingStatusBox offer={offer} />;
    case ENUMS.OfferStatus.WAITING_FOR_DEPOSIT_ACH_CONFIRMATION:
      return <WaitingForMethodVerificationStatusBox offer={offer} />;
    case ENUMS.OfferStatus.WAITING_FOR_DEPOSIT:
    case ENUMS.OfferStatus.DEPOSIT_PAYMENT_IN_PROGRESS:
      return <PaymentInProgressStatusBox offer={offer} />;
    case ENUMS.OfferStatus.DEPOSIT_PAYMENT_FAILED:
      return <PaymentMethodFailedStatusBox offer={offer} />;
    default:
      assertUnreachable(offer.status);
      return null;
  }
};
