import type { GetOfferHistoryParams } from "@js/apps/jobs/apps/offers/types";
import { getOrderingParam } from "@js/utils";

export const processContractHistoryParams = (
  params: GetOfferHistoryParams["filters"],
) => {
  const { order_by, order_dir, ...rest } = params || {};

  const ordering = getOrderingParam({ order_by, order_dir });

  return {
    ...rest,
    ordering,
  };
};
