import type { OfferHistoryItem } from "@js/apps/jobs/apps/offers/types";
import { formatCurrency, formatDate } from "@js/utils";

import type {
  ContractHistoryTableColumnDef,
  ContractHistoryTableParamsOrderBy,
} from "./types";

const formatDateValue = (value: string) => formatDate(value, "MMM D, YYYY");

const getPaymentAmount = (
  amount: string,
  paymentType: EnumType<typeof ENUMS.JobPaymentType>,
) => {
  let label: string;

  switch (paymentType) {
    case ENUMS.JobPaymentType.HOURLY:
      label = "per hour";
      break;
    case ENUMS.JobPaymentType.ANNUAL:
      label = "annually";
      break;
    default:
      label = "";
  }

  return `${formatCurrency(amount)} ${label}`;
};

export const CONTRACT_HISTORY_TABLE_SORTABLE_COLUMNS = [
  "created",
  "proposed_start_date",
  "proposed_end_date",
  "anticipated_weekly_hours",
] as const satisfies ContractHistoryTableParamsOrderBy[];

export const CONTRACT_HISTORY_TABLE_COLUMNS: ContractHistoryTableColumnDef[] = [
  {
    field: "proposed_start_date",
    headerName: "Start date",
    default: true,
    minWidth: 150,
    valueFormatter: (value: OfferHistoryItem["proposed_start_date"]) =>
      value ? formatDateValue(value) : "-",
  },
  {
    field: "proposed_end_date",
    headerName: "End date",
    default: true,
    minWidth: 150,
    valueFormatter: (value: OfferHistoryItem["proposed_end_date"]) =>
      value ? formatDateValue(value) : "-",
  },
  {
    field: "payment_amount",
    headerName: "Pay details",
    minWidth: 205,
    sortable: false,
    default: true,
    renderCell: (params) => {
      return getPaymentAmount(
        params.row.payment_amount,
        params.row.payment_type,
      );
    },
  },
  {
    field: "anticipated_weekly_hours",
    headerName: "Hours per week",
    default: true,
    minWidth: 180,
  },
  {
    field: "created",
    headerName: "Last updated",
    default: true,
    minWidth: 150,
    valueFormatter: (value: OfferHistoryItem["created"]) =>
      formatDateValue(value),
  },
];
