import { useMemo } from "react";
import type { z } from "zod";

import { useQueryParams } from "@js/hooks/query-params";
import type { RhfForm } from "@js/rhf/types";

export const useParsedQueryParams = <T extends z.ZodRawShape>(
  zodSchema: z.ZodEffects<z.ZodObject<T>> | z.ZodObject<T>,
) => {
  const originalParams = useQueryParams();
  const parsedFilters = useMemo(
    () => zodSchema.safeParse(originalParams),
    [originalParams, zodSchema],
  );

  return {
    data: parsedFilters.success ? parsedFilters.data : undefined,
    success: parsedFilters.success,
    parsedObject: parsedFilters,
    originalParams,
  };
};

export const useParsedQueryParamsWithPrefix = <T extends z.ZodRawShape>(
  zodSchema: z.ZodEffects<z.ZodObject<T>> | z.ZodObject<T>,
  prefix: string,
) => {
  const originalParams = useQueryParams();

  const filteredParams = useMemo(() => {
    const obj: Record<string, string | string[]> = {};
    for (const key of Object.keys(originalParams)) {
      if (key.startsWith(prefix)) {
        const keyWithoutPrefix = key.replace(prefix, "");
        obj[keyWithoutPrefix] = originalParams[key];
      }
    }
    return obj;
  }, [originalParams, prefix]);

  const parsedFilters = useMemo(
    () => zodSchema.safeParse(filteredParams),
    [filteredParams, zodSchema],
  );

  return {
    data: parsedFilters.success ? parsedFilters.data : undefined,
    success: parsedFilters.success,
    parsedObject: parsedFilters,
    originalParams,
  };
};

export const useParsedQueryParamsWithDefaults = <T extends z.ZodRawShape>(
  zodSchema: z.ZodEffects<z.ZodObject<T>> | z.ZodObject<T>,
  defaultValues: RhfForm<z.ZodObject<T>>["DefaultValues"],
) => {
  const { parsedObject, ...result } = useParsedQueryParams(zodSchema);

  const data = useMemo(() => {
    return parsedObject.success
      ? { ...defaultValues, ...parsedObject.data }
      : defaultValues;
  }, [parsedObject, defaultValues]);

  return {
    ...result,
    data,
  };
};

export const useParsedQueryParamsWithPrefixAndDefaults = <
  T extends z.ZodRawShape,
  K extends Partial<RhfForm<z.ZodObject<T>>["DefaultValues"]>,
>(
  zodSchema: z.ZodEffects<z.ZodObject<T>> | z.ZodObject<T>,
  prefix: string,
  defaultValues: K,
) => {
  const { parsedObject, ...result } = useParsedQueryParamsWithPrefix(
    zodSchema,
    prefix,
  );

  const data: typeof parsedObject.data & K = useMemo(() => {
    return parsedObject.success
      ? { ...defaultValues, ...parsedObject.data }
      : defaultValues;
  }, [defaultValues, parsedObject.data, parsedObject.success]);

  return {
    ...result,
    data,
  };
};
