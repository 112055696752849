import type { Control, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import type { SliderProps } from "@hexocean/braintrust-ui-components";
import { Slider } from "@hexocean/braintrust-ui-components";

export type RhfSliderProps<T extends FieldValues> = SliderProps &
  UseControllerProps<T> & {
    id: SliderProps["id"];
    control: Control<T>;
  };

export const RhfSlider = <T extends FieldValues>({
  id,
  ...props
}: RhfSliderProps<T>) => {
  const { field } = useController(props);

  return (
    <Slider
      id={id}
      {...props}
      {...field}
      onChange={(_event, value) => {
        field.onChange(value as number[]);
      }}
    />
  );
};
