import { Button } from "@hexocean/braintrust-ui-components";
import type { FreelancerOffer } from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";

import { MessageButton } from "./message-button";

export type TalentOfferCTAButtonsProps = {
  offer: FreelancerOffer;
  onOfferReject: () => void;
};

export const TalentOfferCTAButtons = ({
  offer,
  onOfferReject,
}: TalentOfferCTAButtonsProps) => {
  const interlocutorId = offer.job.creator.id;

  switch (offer.status) {
    case ENUMS.OfferStatus.ACCEPTED:
    case ENUMS.OfferStatus.CANCELLED:
    case ENUMS.OfferStatus.REJECTED:
    case ENUMS.OfferStatus.EXPIRED:
    case ENUMS.OfferStatus.EXTENDED:
      return <MessageButton interlocutor={interlocutorId} />;

    case ENUMS.OfferStatus.NEW:
    case ENUMS.OfferStatus.UPDATED:
      return (
        <>
          <MessageButton interlocutor={interlocutorId} />
          <Button
            variant="destructive"
            shape="squared"
            size="medium"
            onClick={() => onOfferReject()}
          >
            Reject
          </Button>
          <AcceptOfferButton />
        </>
      );

    default:
      assertUnreachable(offer.status);
      return null;
  }
};

const AcceptOfferButton = () => {
  return (
    <>
      <Button variant="positive" shape="squared" type="submit" size="medium">
        Accept offer
      </Button>
    </>
  );
};
