import type { ComponentPropsWithoutRef, ReactNode } from "react";
import React from "react";

import {
  Box,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { OfferDetailsUpdateHistoryTable } from "@js/apps/jobs/apps/offers/components/details/update-history-table";
import { showOfferExpiresSection } from "@js/apps/jobs/apps/offers/utils";
import { getJobTypeTagMeta } from "@js/apps/jobs/components";
import { Date } from "@js/components/date";
import type { EmployerOffer, FreelancerOffer, Job } from "@js/types/jobs";
import {
  assertUnreachable,
  dateDifferenceFromNow,
  formatCurrency,
  getEnumLabel,
  pluralize,
} from "@js/utils";
import type { IsoDate } from "@js/utils/date/types";
import { DateFormats } from "@js/utils/date/types";

import { JOB_PAYMENT_TYPE_LABELS } from "../../constants";

import { OfferDetailsHeader } from "./header";

import styles from "./style.module.scss";

export type TalentOrEmployerOffer = EmployerOffer | FreelancerOffer;

type OfferDetailsProps = {
  job: Job;
  offer: TalentOrEmployerOffer;
  showUpdatedLabel?: boolean;
};

const UpdatedContext = React.createContext(false);

const useUpdatedContext = () => {
  const context = React.useContext(UpdatedContext);

  if (typeof context !== "boolean") {
    throw new Error(
      "useUpdatedContext must be in scope of UpdatedContext provider",
    );
  }

  return context;
};

export const OfferDetails = ({
  offer,
  job,
  showUpdatedLabel = false,
}: OfferDetailsProps) => {
  if (
    job.job_type !== ENUMS.JobType.DIRECT_HIRE &&
    job.job_type !== ENUMS.JobType.FREELANCE &&
    job.job_type !== ENUMS.JobType.GRANT
  ) {
    assertUnreachable(job.job_type);
  }

  return (
    <UpdatedContext.Provider value={showUpdatedLabel}>
      <RoundedBox>
        <OfferDetailsHeader offer={offer} />
        <OfferDetailsFacade offer={offer} job={job} />
        <OfferDetailsUpdateHistoryTable offer={offer} />
      </RoundedBox>
    </UpdatedContext.Provider>
  );
};

type OfferDetailsFacadeProps = {
  offer: TalentOrEmployerOffer;
  job: Job;
};

const OfferDetailsFacade = ({ offer, job }: OfferDetailsFacadeProps) => {
  const showOfferExpires = showOfferExpiresSection(offer?.status);
  return (
    <DetailsLayoutContainer mt={3}>
      <Box display="flex" flexDirection="column" gap={2}>
        <JobTypeDetal job={job} />
        <PayRateDetail offer={offer} />
        <PlannedDatesDetail offer={offer} />
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <CommitmentDetail offer={offer} />
        {showOfferExpires && <OfferExpiresDetail offer={offer} />}
      </Box>
    </DetailsLayoutContainer>
  );
};

type DetailsLayoutContainerProps = ComponentPropsWithoutRef<typeof Box> & {
  children: ReactNode;
};

const DetailsLayoutContainer = (props: DetailsLayoutContainerProps) => {
  return <Box className={styles.layout} {...props} />;
};

type JobTypeDetalProps = {
  job: Job;
};

const JobTypeDetal = ({ job }: JobTypeDetalProps) => {
  const user = useUser();

  const { label } = getJobTypeTagMeta(job.job_type, user?.account_type);

  return <Detail label="Job type" value={label} />;
};

type OfferDetailProps = {
  offer: TalentOrEmployerOffer;
};

const PayRateDetail = ({ offer }: OfferDetailProps) => {
  const amount = offer.payment_amount;
  const paymentType = offer.payment_type;

  const isUpdated =
    offer.updated_fields.includes("payment_amount") ||
    offer.updated_fields.includes("payment_type");

  return (
    <Detail
      updated={isUpdated}
      label="Pay rate"
      value={`${formatCurrency(amount)} ${getEnumLabel(
        JOB_PAYMENT_TYPE_LABELS,
        paymentType,
      )}`}
    />
  );
};

const PlannedDatesDetail = ({ offer }: OfferDetailProps) => {
  const startDate = offer.proposed_start_date as IsoDate;
  const endDate = offer.proposed_end_date as IsoDate;
  const content = (
    <>
      {startDate && (
        <>
          <Date date={startDate} format={DateFormats["Jan 1, 1970"]} />
          {" - "}
        </>
      )}
      {endDate && (
        <>
          Ends <Date date={endDate} format={DateFormats["Jan 1, 1970"]} />
        </>
      )}
    </>
  );

  const isUpdated =
    offer.updated_fields.includes("proposed_start_date") ||
    offer.updated_fields.includes("proposed_end_date");

  return <Detail updated={isUpdated} label="Dates" value={content} />;
};

const CommitmentDetail = ({ offer }: OfferDetailProps) => {
  return (
    <Detail
      updated={offer.updated_fields.includes("anticipated_weekly_hours")}
      label="Commitment"
      value={`${offer.anticipated_weekly_hours} hours per week`}
    />
  );
};

const OfferExpiresDetail = ({ offer }: OfferDetailProps) => {
  const date = offer.time_of_expiry as IsoDate;
  const timeOfExpiry = dateDifferenceFromNow(offer.time_of_expiry, "days");
  const expireString = (
    <>
      {timeOfExpiry > 0 && (
        <>
          In {timeOfExpiry} day{pluralize(timeOfExpiry)} |{" "}
        </>
      )}
      <Date date={date} format={DateFormats["Jan 1, 1970"]} />
    </>
  );

  return <Detail label="Offer expires" value={expireString} />;
};

type DetailProps = {
  label: string;
  value: string | JSX.Element;
  updated?: boolean;
};

const Detail = ({ label, value, updated }: DetailProps) => {
  const showUpdatedLabel = useUpdatedContext();

  const _label =
    showUpdatedLabel && updated ? (
      <>
        {label} - <span className="positive-2">Updated</span>
      </>
    ) : (
      label
    );

  return (
    <Box>
      <Typography variant="label" size="small" component="h3" color="grey-1">
        {_label}
      </Typography>
      <Typography size="large" component="p">
        {value}
      </Typography>
    </Box>
  );
};
