import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { JobActionButtonBase } from "@js/apps/jobs/components/job-action-buttons/job-action-button-base/job-action-button-base";
import { assertUnreachable } from "@js/utils";

import { RespondToOfferButton, ReviewContractButton } from "./components";

type JobsOfferActionButtonProps = {
  jobId: number;
  offerId?: number | null;
  statusForFreelancer?: EnumType<typeof ENUMS.JobFreelancerStatus>;
  size?: ButtonProps["size"];
};

export const JobsOfferActionButton = ({
  jobId,
  offerId,
  statusForFreelancer,
  size,
}: JobsOfferActionButtonProps) => {
  if (!offerId || !statusForFreelancer) return null;

  switch (statusForFreelancer) {
    case ENUMS.JobFreelancerStatus.ACCEPTING_APPLICATIONS:
    case ENUMS.JobFreelancerStatus.INVITED_BY_CLIENT:
    case ENUMS.JobFreelancerStatus.INVITED_BY_MATCHER:
    case ENUMS.JobFreelancerStatus.PENDING_APPROVAL:
    case ENUMS.JobFreelancerStatus.APPLICATION_SENT:
    case ENUMS.JobFreelancerStatus.IN_REVIEW:
    case ENUMS.JobFreelancerStatus.APPLICATION_REJECTED:
    case ENUMS.JobFreelancerStatus.LIVE_INTERVIEWING:
    case ENUMS.JobFreelancerStatus.AI_INTERVIEWING:
    case ENUMS.JobFreelancerStatus.IN_REVIEW_AFTER_AI_INTERVIEW:
      return null;

    case ENUMS.JobFreelancerStatus.NEW_OFFER:
    case ENUMS.JobFreelancerStatus.UPDATED_OFFER:
      return (
        <RespondToOfferButton
          jobId={jobId}
          offerId={offerId}
          variant="primary"
          size={size}
        />
      );
    case ENUMS.JobFreelancerStatus.OFFER_EXTENDED:
    case ENUMS.JobFreelancerStatus.OFFER_ACCEPTED:
      return (
        <ReviewContractButton
          jobId={jobId}
          offerId={offerId}
          variant="primary"
          size={size}
        />
      );

    case ENUMS.JobFreelancerStatus.JOB_FILLED:
    case ENUMS.JobFreelancerStatus.JOB_CLOSED:
    case ENUMS.JobFreelancerStatus.JOB_ON_HOLD:
      return (
        <JobActionButtonBase
          label="View job"
          variant="primary"
          withIcon
          to={`/jobs/${jobId}`}
          size={size}
        />
      );

    default: {
      assertUnreachable(statusForFreelancer);
      return null;
    }
  }
};
