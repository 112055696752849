import { Box, Tooltip, Typography } from "@hexocean/braintrust-ui-components";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";

import {
  getApplicationStatusColorVariant,
  getApplicationTooltipText,
} from "./utils";

import styles from "./style.module.scss";

export type ApplicationStatusWithTooltipProps = {
  status: EnumType<typeof ENUMS.JobFreelancerStatus> | undefined;
  appliedDate: string | null | undefined;
  offerCreatedDate: string | null | undefined;
  offerAcceptedDate: string | null | undefined;
  offerUpdatedDate: string | null | undefined;
};

export const ApplicationStatusWithTooltip = ({
  status,
  appliedDate,
  offerCreatedDate,
  offerAcceptedDate,
  offerUpdatedDate,
}: ApplicationStatusWithTooltipProps) => {
  // There is no pill for this status
  if (status === ENUMS.JobFreelancerStatus.ACCEPTING_APPLICATIONS || !status)
    return null;

  const title = getApplicationTooltipText({
    status,
    appliedDate,
    offerCreatedDate,
    offerAcceptedDate,
    offerUpdatedDate,
  });
  const variant = getApplicationStatusColorVariant(status);

  return (
    <Tooltip
      title={title}
      color="var(--black)"
      placement="top"
      maxWidth={238}
      disabled={!title}
    >
      <span className={styles.tooltip} style={{ minWidth: 0 }}>
        <Box display="flex" gap="4px" alignItems="center">
          <Typography
            className={styles.applicationStatusPill}
            component="p"
            size="small"
            title={ENUMS.JobFreelancerStatusLabels[status]}
            ellipsis
            sx={{
              backgroundColor: variant.bgColor,
              color: variant.textColor,
            }}
          >
            {ENUMS.JobFreelancerStatusLabels[status]}
          </Typography>
          {title && <InfoIcon sx={{ fontSize: "20px" }} />}
        </Box>
      </span>
    </Tooltip>
  );
};
