import { Box } from "@hexocean/braintrust-ui-components";
import { ApplicationStatusWithTooltip } from "@js/apps/jobs/components/application-status";
import { BoostedJobBadge } from "@js/apps/jobs/components/boosted-job-badge";
import type { MainJobListingJob } from "@js/types/jobs";

import styles from "./styles.module.scss";

type JobItemApplicationStatusProps = {
  statusForFreelancer: EnumType<typeof ENUMS.JobFreelancerStatus>;
  job: MainJobListingJob;
};

export const JobItemApplicationStatus = ({
  statusForFreelancer,
  job,
}: JobItemApplicationStatusProps) => {
  if (statusForFreelancer === ENUMS.JobFreelancerStatus.ACCEPTING_APPLICATIONS)
    return null;

  return (
    <Box className={styles.wrapper}>
      {job.is_boosted && (
        <BoostedJobBadge
          jobId={job.id}
          statusForFreelancer={job?.status_for_freelancer}
        />
      )}
      <ApplicationStatusWithTooltip
        status={statusForFreelancer}
        offerAcceptedDate={job.offer_accepted_at}
        offerCreatedDate={job.offer_created_at}
        appliedDate={job.bid_created_at}
        offerUpdatedDate={job.offer_updated_at}
      />
    </Box>
  );
};
