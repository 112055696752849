import _ from "underscore";

import { getMakeOfferMessage } from "@js/services";
import type { EmployerOffer, InitialOffer } from "@js/types/jobs";
import type { PaymentMethod } from "@js/types/payments";
import { getLocalDate } from "@js/utils";
import { assertUnreachable } from "@js/utils";

import { OFFER_FIELDS } from "../constants";
import type { CreateOfferFormData, EditOfferFormData } from "../types";

type OfferType = InitialOffer | EmployerOffer;

const getCommonInitialValues = (
  offer: OfferType,
  paymentMethod?: PaymentMethod,
): Omit<EditOfferFormData, "note_to_talent"> => {
  return {
    bid: offer.bid?.id,
    [OFFER_FIELDS.anticipated_weekly_hours]:
      offer.anticipated_weekly_hours || 20,
    [OFFER_FIELDS.talent_has_own_equipment]:
      offer.talent_has_own_equipment ?? true,
    [OFFER_FIELDS.payment_amount]: offer.payment_amount,
    [OFFER_FIELDS.payment_type]: offer.payment_type,
    [OFFER_FIELDS.project_duration]: offer.project_duration,
    [OFFER_FIELDS.proposed_start_date]:
      offer.proposed_start_date && offer.proposed_end_date
        ? offer.proposed_start_date
        : null,
    [OFFER_FIELDS.proposed_end_date]:
      offer.proposed_start_date && offer.proposed_end_date
        ? offer.proposed_end_date
        : null,
    [OFFER_FIELDS.require_paperwork]: offer.require_paperwork ?? false,
    [OFFER_FIELDS.deposit_payment_method]:
      offer.deposit_payment_method || paymentMethod,
  };
};

export const getCreateOfferInitialValues = (
  offer: InitialOffer | null | undefined,
  paymentMethod?: PaymentMethod,
): Partial<CreateOfferFormData> | null => {
  if (!offer || !offer.bid?.id) return null;

  return {
    ...getCommonInitialValues(offer, paymentMethod),
    dry_run: true,
    note_to_talent: getMakeOfferMessage(offer),
  };
};

export const getEditOfferInitialValues = (
  offer: EmployerOffer | null | undefined,
): Partial<EditOfferFormData> | null => {
  if (!offer || !offer.bid?.id) return null;

  return {
    ...getCommonInitialValues(offer),
    note_to_talent: offer.note_to_talent,
  };
};

export function assertInitialized(
  initialValues: unknown,
): asserts initialValues is CreateOfferFormData | EditOfferFormData {
  if (_.isEmpty(initialValues)) {
    throw new Error(
      "Initial values are empty - they should be already initialized when they reach this line",
    );
  }
}

export const isOfferStatusPending = (
  status: EnumType<typeof ENUMS.OfferStatus>,
) => {
  switch (status) {
    case ENUMS.OfferStatus.NEW:
    case ENUMS.OfferStatus.UPDATED: {
      return true;
    }
    case ENUMS.OfferStatus.ACCEPTED:
    case ENUMS.OfferStatus.CANCELLED:
    case ENUMS.OfferStatus.REJECTED:
    case ENUMS.OfferStatus.EXPIRED:
    case ENUMS.OfferStatus.EXTENDED:
    case ENUMS.OfferStatus.DEPOSIT_PAYMENT_FAILED:
    case ENUMS.OfferStatus.WAITING_FOR_DEPOSIT:
    case ENUMS.OfferStatus.WAITING_FOR_DEPOSIT_ACH_CONFIRMATION:
    case ENUMS.OfferStatus.DEPOSIT_PAYMENT_IN_PROGRESS:
    case undefined: {
      return false;
    }
    default: {
      assertUnreachable(status);
    }
  }
};

export const isOfferStatusEditable = (
  status: EnumType<typeof ENUMS.OfferStatus>,
) => {
  switch (status) {
    case ENUMS.OfferStatus.NEW:
    case ENUMS.OfferStatus.WAITING_FOR_DEPOSIT:
    case ENUMS.OfferStatus.WAITING_FOR_DEPOSIT_ACH_CONFIRMATION:
    case ENUMS.OfferStatus.DEPOSIT_PAYMENT_IN_PROGRESS:
    case ENUMS.OfferStatus.DEPOSIT_PAYMENT_FAILED:
    case ENUMS.OfferStatus.UPDATED: {
      return true;
    }
    case ENUMS.OfferStatus.ACCEPTED:
    case ENUMS.OfferStatus.EXTENDED:
    case ENUMS.OfferStatus.CANCELLED:
    case ENUMS.OfferStatus.REJECTED:
    case ENUMS.OfferStatus.EXPIRED:
    case undefined: {
      return false;
    }
    default: {
      assertUnreachable(status);
    }
  }
};

export const showOfferExpiresSection = (
  status: EnumType<typeof ENUMS.OfferStatus>,
) => {
  switch (status) {
    case ENUMS.OfferStatus.ACCEPTED:
    case ENUMS.OfferStatus.EXTENDED:
    case ENUMS.OfferStatus.REJECTED:
    case ENUMS.OfferStatus.CANCELLED: {
      return false;
    }
    case ENUMS.OfferStatus.NEW:
    case ENUMS.OfferStatus.WAITING_FOR_DEPOSIT:
    case ENUMS.OfferStatus.WAITING_FOR_DEPOSIT_ACH_CONFIRMATION:
    case ENUMS.OfferStatus.DEPOSIT_PAYMENT_IN_PROGRESS:
    case ENUMS.OfferStatus.DEPOSIT_PAYMENT_FAILED:
    case ENUMS.OfferStatus.UPDATED:
    case ENUMS.OfferStatus.EXPIRED: {
      return true;
    }
    default: {
      assertUnreachable(status);
    }
  }
};

export const formatOffersDate = (date: string, format?: string) => {
  const dateToLocal = getLocalDate(date);
  return dateToLocal.format(format);
};

export const prepareSubmitButtonLabel = (
  paymentMethod?: EnumType<typeof ENUMS.PaymentContentType>,
) => {
  switch (paymentMethod) {
    case ENUMS.PaymentContentType.StripeACHPaymentMethod:
      return "Submit offer";
    default:
      return "Send offer";
  }
};

export const getPaymentMethodContent = (
  paymentMethod: PaymentMethod | null,
) => {
  if (!paymentMethod) return "";

  const contentType = paymentMethod.content_type;

  switch (contentType) {
    case ENUMS.PaymentContentType.StripeCreditCardPaymentMethod: {
      const lastNumbers = paymentMethod.method.last4;

      return `Credit card ending in ${lastNumbers}`;
    }
    case ENUMS.PaymentContentType.StripeACHPaymentMethod: {
      const lastNumbers = paymentMethod.method.account_number.slice(-4);

      return `ACH ending in ${lastNumbers}`;
    }
    default:
      assertUnreachable(contentType);
      break;
  }
};

export const getInitialPaymentMethodOnCreateOffer = (
  isDepositRequired: boolean | undefined,
  paymentMethods: PaymentMethod[],
) => {
  if (!isDepositRequired) return;

  const methodToSet = paymentMethods.find((method) => method.is_default);

  return methodToSet || paymentMethods[0];
};
