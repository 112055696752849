import { assertUnreachable } from "@js/utils";

import { CancelOfferButton } from "../cancel-offer-button";
import { EditOfferButton } from "../edit-offer-button";
import { ReviewOfferButton } from "../review-offer-button/review-offer-button";

import { MessageButton } from "./message-button";

type EmployerOfferCTAButtonsProps = {
  jobId: number;
  offerId?: number;
  offerStatus: EnumType<typeof ENUMS.OfferStatus> | null;
  previewPage?: boolean;
  interlocutor: number;
};

export const EmployerOfferCTAButtons = ({
  jobId,
  offerId,
  offerStatus,
  previewPage,
  interlocutor,
}: EmployerOfferCTAButtonsProps) => {
  switch (offerStatus) {
    case null:
      return <ReviewOfferButton />;

    case ENUMS.OfferStatus.ACCEPTED:
    case ENUMS.OfferStatus.CANCELLED:
    case ENUMS.OfferStatus.REJECTED:
    case ENUMS.OfferStatus.EXPIRED:
    case ENUMS.OfferStatus.EXTENDED:
      return <MessageButton interlocutor={interlocutor} />;

    case ENUMS.OfferStatus.NEW:
    case ENUMS.OfferStatus.UPDATED:
    case ENUMS.OfferStatus.WAITING_FOR_DEPOSIT:
    case ENUMS.OfferStatus.WAITING_FOR_DEPOSIT_ACH_CONFIRMATION:
    case ENUMS.OfferStatus.DEPOSIT_PAYMENT_FAILED:
    case ENUMS.OfferStatus.DEPOSIT_PAYMENT_IN_PROGRESS:
      return (
        <>
          {offerId && <CancelOfferButton offerId={offerId} jobId={jobId} />}
          {previewPage && offerId ? (
            <EditOfferButton jobId={jobId} offerId={offerId} />
          ) : (
            <ReviewOfferButton />
          )}
          <MessageButton interlocutor={interlocutor} />
        </>
      );

    default:
      assertUnreachable(offerStatus);
      return null;
  }
};
