import { Typography } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";
import { InteractiveTag } from "@js/components/interactive-text";
import type { FreelancerOffer } from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";

type TalentTopStripeContentProps = {
  offer: FreelancerOffer;
};

export const TalentTopStripeContent = ({
  offer,
}: TalentTopStripeContentProps) => {
  const user = useUser();
  const { openMessengerModal } = useOpenMessengerModal();
  const handleUserAction = () => {
    const employerId = offer?.creator?.id || offer?.job.creator?.id;
    if (!user?.id || !employerId) {
      return;
    }
    openMessengerModal({
      context: {
        participants: [user?.id, employerId],
      },
    });
  };

  switch (offer.status) {
    case ENUMS.OfferStatus.NEW:
    case ENUMS.OfferStatus.UPDATED:
      return (
        <Typography component="p" variant="paragraph" p={0.62}>
          Make sure the details of this offer are correct before you accept. If
          anything looks wrong,{" "}
          <InteractiveTag
            tag="u"
            className="pointer"
            tabIndex={0}
            onClick={handleUserAction}
          >
            message the Client
          </InteractiveTag>{" "}
          right away.
        </Typography>
      );

    case ENUMS.OfferStatus.ACCEPTED:
    case ENUMS.OfferStatus.CANCELLED:
    case ENUMS.OfferStatus.REJECTED:
    case ENUMS.OfferStatus.EXTENDED:
    case ENUMS.OfferStatus.EXPIRED:
    case null:
      return null;

    default:
      assertUnreachable(offer.status);
      return null;
  }
};
