import { Button, type ButtonProps } from "@hexocean/braintrust-ui-components";
import { ArrowTopRightIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

type JobActionButtonBaseProps = ButtonProps & {
  label: string;
  withIcon?: boolean;
};

export const JobActionButtonBase = ({
  label,
  withIcon,
  ...props
}: JobActionButtonBaseProps) => {
  return (
    <Button
      rel="noopener noreferrer"
      target="_blank"
      RouterLink={RouterLink}
      {...props}
      sx={{
        ...props.sx,
        display: { xs: "none", sm: "flex" },
        flexShrink: 0,
        height: { sm: 40 },
      }}
    >
      {label}
      {withIcon && (
        <ArrowTopRightIcon
          style={{
            fontSize: props.size === "x-small" ? 16 : 24,
            marginLeft: 8,
          }}
        />
      )}
    </Button>
  );
};
