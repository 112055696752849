import type { OfferButtonProps } from "@js/apps/jobs/components/job-action-buttons/components";
import { JobActionButtonBase } from "@js/apps/jobs/components/job-action-buttons/job-action-button-base";

export const ViewContractButton = ({
  jobId,
  offerId,
  ...props
}: OfferButtonProps) => {
  return (
    <JobActionButtonBase
      label="View contract"
      to={`/jobs/${jobId}/offers/${offerId}`}
      {...props}
    />
  );
};
