import { useEffect, useState } from "react";
import classNames from "classnames";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { ButtonForTextMessage } from "@js/apps/messenger/components/button-for-text-message";
import type { Message } from "@js/types/messenger";
import { shouldDisplaySystemMessageComponent } from "@js/types/messenger";

import { formatSentDate } from "../../utils";
import { MessageAttachments } from "../message-attachments";
import { SystemMessageComponent } from "../system-message";

import { AntiSpamComponent } from "./anti-spam-component";
import { TextMessage } from "./text-message";

import styles from "./styles.module.scss";

const OPTIMISTIC_MESSAGE_TIMEOUT = 5000;

type GenericMessengerMessageProps = {
  message: Message;
  isOwner: boolean;
  showUserData: boolean;
  wasReceiverHiredByClient: boolean;
};

export const GenericMessengerMessage = ({
  message,
  isOwner,
  showUserData,
  wasReceiverHiredByClient,
}: GenericMessengerMessageProps): JSX.Element => {
  const [error, setError] = useState("");

  useEffect(() => {
    const isMessageSentByUser =
      message.optimistic_id &&
      message.optimistic_id !== "REPLACED" &&
      message.optimistic_id !== "DELIVERED";
    if (isOwner && isMessageSentByUser) {
      const timeout = setTimeout(() => {
        setError("Failed to send message.");
      }, OPTIMISTIC_MESSAGE_TIMEOUT);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isOwner, message]);

  const hasContent = !!message.content;
  const sentDate = formatSentDate(message.sent);
  const shouldDisplayRedirectWarning =
    message.author?.account_type === ENUMS.AccountType.EMPLOYER &&
    !wasReceiverHiredByClient &&
    !isOwner;

  if (shouldDisplaySystemMessageComponent(message)) {
    return (
      <Box className={classNames(styles.message)} data-testid="message">
        <SystemMessageComponent systemMessage={message} />
      </Box>
    );
  }

  return (
    <div
      className={classNames(styles.message, {
        [styles.messageLeft]: !isOwner,
        [styles.messageRight]: isOwner,
      })}
      data-testid="message"
    >
      {showUserData && hasContent && (
        <Typography
          component="p"
          size="small"
          color="grey-2"
          className={classNames(styles.messageTimestamp)}
          data-testid="message-sent-date"
        >
          {sentDate}
        </Typography>
      )}
      <div title={sentDate} className={classNames(styles.content)}>
        {message.is_spam ? (
          <AntiSpamComponent message={message} isOwner={isOwner} />
        ) : (
          <>
            <TextMessage
              message={message}
              isOwner={isOwner}
              variant={isOwner ? "teal" : "soft-grey"}
              shouldDisplayRedirectWarning={shouldDisplayRedirectWarning}
            >
              <ButtonForTextMessage message={message} isOwner={isOwner} />
            </TextMessage>

            <MessageAttachments
              calendar_link={message.calendar_link}
              calendar_link_data={message.links_metadata?.[0]}
              messageAttachments={message.attachments}
              report={{ isOwner, message }}
              isOwner={isOwner}
            />
          </>
        )}
        {error && (
          <Typography
            component="p"
            error
            size="small"
            color="negative"
            key={error}
          >
            {error}
          </Typography>
        )}
      </div>
    </div>
  );
};
