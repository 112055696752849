import { z } from "zod";

import type { SystemMessageToDisplayInSystemMessageComponent } from "@js/apps/messenger/components/system-message/system-message-component";
import { typeGuard } from "@js/utils";

import type { User } from "./auth";
import type { LinkMetadata } from "./common";
import type { PaginatedResult } from "./generic";
import type { HelpOffer } from "./give-and-get-help";
import type { Attachment } from "./uploads";

export type Message = UserMessage | SystemMessage;

export type BaseMessage = {
  id: number;
  room: number;
  created: string;
  sent: string;
  optimistic_id?: string;
  links_metadata: LinkMetadata[];
  last_message_content?: string;
  is_spam: boolean;
};

export type MessageAttachment = {
  id: number;
  link: string;
  safe_file_name: string;
  thumbnail: string | null;
  attachment: Omit<Attachment, "thumbnail">;
};

type BaseUserMessage = BaseMessage & {
  author: MessageAuthor;
  content: string | null;
  help_offer: HelpOffer | null;
  calendar_link: string | null;
  system_message_active: false;
  system_message_target: null;
  attachments: MessageAttachment[] | null;
  is_spam: boolean;
};

export type UserMessage = BaseUserMessage & {
  message_type: typeof ENUMS.MessageType.USER_MESSAGE;
};

export type BaseSystemMessage = BaseMessage & {
  author: null;
  content: null;
  help_offer: null;
  calendar_link: null;
  system_message_active: boolean;
};

export type UserSystemMessage =
  | OfferExtendedSystemMessage
  | MessageReportedSystemMessage
  | UserReportedSystemMessage
  | CancelReportsSystemMessage;

export type HelpOfferSystemMessage =
  | HelpOfferReportedSystemMessage
  | HelpOfferCreatedSystemMessage
  | HelpOfferBudgetChangedSystemMessage
  | HelpOfferDeletedSystemMessage
  | HelpOfferDeclinedSystemMessage
  | HelpOfferExpiredSystemMessage
  | HelpOfferAcceptedSystemMessage
  | HelpOfferMarkedCompleteSystemMessage
  | HelpOfferRevisionRequestedSystemMessage
  | HelpOfferCompletedSystemMessage
  | HelpOfferRefundRequestedSystemMessage
  | HelpOfferRefundRequestCancelledSystemMessage
  | HelpOfferReviewSentSystemMessage
  | HelpOfferRefundIssuedSystemMessage;

export type SystemMessage = UserSystemMessage | HelpOfferSystemMessage;

export type OfferExtendedSystemMessage = BaseUserMessage & {
  message_type: typeof ENUMS.MessageType.OFFER_EXTENDED;
  system_message_target: null;
  message_context: {
    job_id: number;
    offer_id: number;
  };
};

export type MessageReportedSystemMessage = BaseSystemMessage & {
  message_type: typeof ENUMS.MessageType.MESSAGE_REPORTED;
  system_message_target: z.infer<typeof ExpectedMessageReportedResults>;
};

export type UserReportedSystemMessage = BaseSystemMessage & {
  message_type: typeof ENUMS.MessageType.USER_REPORTED;
  system_message_target: z.infer<typeof ExpectedUserReportedResults>;
};

export type CancelReportsSystemMessage = BaseSystemMessage & {
  message_type: typeof ENUMS.MessageType.USER_REPORTS_CANCELLED;
  system_message_target: z.infer<typeof ExpectedReportsCancelledResults>;
};

export type HelpOfferReportedSystemMessage = BaseSystemMessage & {
  message_type: typeof ENUMS.MessageType.HELP_OFFER_REPORTED;
  system_message_target: z.infer<typeof ExpectedHelpOfferReportedResults>;
};

export type HelpOfferCreatedSystemMessage = BaseSystemMessage & {
  message_type: typeof ENUMS.MessageType.HELP_OFFER_CREATED;
  system_message_target: z.infer<typeof ExpectedHelpOfferCreatedResults>;
};

export type HelpOfferBudgetChangedSystemMessage = BaseSystemMessage & {
  message_type: typeof ENUMS.MessageType.HELP_OFFER_BUDGET_CHANGED;
  system_message_target: z.infer<typeof ExpectedHelpOfferBudgetChangedResults>;
};

export type HelpOfferDeletedSystemMessage = BaseSystemMessage & {
  message_type: typeof ENUMS.MessageType.HELP_OFFER_DELETED;
  system_message_target: z.infer<typeof ExpectedHelpOfferDeletedResults>;
};
export type HelpOfferDeclinedSystemMessage = BaseSystemMessage & {
  message_type: typeof ENUMS.MessageType.HELP_OFFER_DECLINED;
  system_message_target: z.infer<typeof ExpectedHelpOfferDeclinedResults>;
};

export type HelpOfferExpiredSystemMessage = BaseSystemMessage & {
  message_type: typeof ENUMS.MessageType.HELP_OFFER_EXPIRED;
  system_message_target: z.infer<typeof ExpectedHelpOfferExpiredResults>;
};

export type HelpOfferAcceptedSystemMessage = BaseSystemMessage & {
  message_type: typeof ENUMS.MessageType.HELP_OFFER_ACCEPTED;
  system_message_target: z.infer<typeof ExpectedHelpOfferAcceptedResults>;
};

export type HelpOfferMarkedCompleteSystemMessage = BaseSystemMessage & {
  message_type: typeof ENUMS.MessageType.HELP_OFFER_MARKED_COMPLETE;
  system_message_target: z.infer<typeof ExpectedHelpOfferMarkedCompleteResults>;
};

export type HelpOfferRevisionRequestedSystemMessage = BaseSystemMessage & {
  message_type: typeof ENUMS.MessageType.HELP_OFFER_REVISION_REQUESTED;
  system_message_target: z.infer<
    typeof ExpectedHelpOfferRevisionRequestedResults
  >;
};
export type HelpOfferRefundRequestedSystemMessage = BaseSystemMessage & {
  message_type: typeof ENUMS.MessageType.HELP_OFFER_REFUND_REQUESTED;
  system_message_target: z.infer<
    typeof ExpectedHelpOfferRefundRequestedResults
  >;
};

export type HelpOfferRefundRequestCancelledSystemMessage = BaseSystemMessage & {
  message_type: typeof ENUMS.MessageType.HELP_OFFER_REFUND_REQUEST_CANCELED;
  system_message_target: z.infer<
    typeof ExpectedHelpOfferRefundRequestCancelledResults
  >;
};

export type HelpOfferRefundIssuedSystemMessage = BaseSystemMessage & {
  message_type: typeof ENUMS.MessageType.HELP_OFFER_REFUND_ISSUED;
  system_message_target: z.infer<typeof ExpectedHelpOfferRefundIssuedResults>;
};

export type HelpOfferCompletedSystemMessage = BaseSystemMessage & {
  message_type: typeof ENUMS.MessageType.HELP_OFFER_COMPLETED;
  system_message_target: z.infer<typeof ExpectedHelpOfferCompletedResults>;
};

export type HelpOfferReviewSentSystemMessage = BaseSystemMessage & {
  message_type: typeof ENUMS.MessageType.HELP_OFFER_REVIEW_SENT;
  system_message_target: z.infer<typeof ExpectedHelpOfferReviewSentResults>;
};

const ExpectedCommonResults = z.object({
  id: z.number(),
  status: z.nativeEnum(ENUMS.HelpOfferStatus),
  accepted_at: z.string().datetime().nullable(),
  author_id: z.number(),
  receiver: z.object({
    id: z.number(), // freelancer id
    user: z.object({ id: z.number(), first_name: z.string() }),
  }),
  reported: z.boolean(),
  budget: z.string(),
  fee: z.string(),
});

const ExpectedReportedCommonResults = ExpectedCommonResults.pick({ id: true });

export const ExpectedMessageReportedResults =
  ExpectedReportedCommonResults.extend({
    author: z.object({ public_name: z.string() }),
  });

export const ExpectedUserReportedResults = ExpectedReportedCommonResults.extend(
  {
    public_name: z.string(),
    first_name: z.string(),
    last_name: z.string().nullable(),
  },
);

export const ExpectedReportsCancelledResults =
  ExpectedReportedCommonResults.extend({
    public_name: z.string(),
  });

export const ExpectedHelpOfferReportedResults = ExpectedCommonResults.extend({
  author: z.object({
    user: z.object({ first_name_possessive: z.string() }),
  }),
});

export const ExpectedHelpOfferCreatedResults = ExpectedCommonResults.extend({
  author: z.object({
    user: z.object({ id: z.number(), first_name: z.string() }),
  }),
  comment: z
    .object({
      main_post_title: z.string(),
      id: z.number(),
      main_post_id: z.number(),
      text: z.string(),
    })
    .nullable(),
  category: z.object({ name: z.string() }),
});

export const ExpectedHelpOfferBudgetChangedResults =
  ExpectedCommonResults.extend({
    author: z.object({
      user: z.object({ first_name: z.string() }),
    }),
  });

export const ExpectedHelpOfferDeletedResults = ExpectedCommonResults.extend({
  author: z.object({
    user: z.object({ first_name: z.string() }),
  }),
  category: z.object({ name: z.string() }),
});

export const ExpectedHelpOfferDeclinedResults = ExpectedCommonResults.extend({
  author: z.object({
    user: z.object({ first_name_possessive: z.string() }),
  }),
  category: z.object({ name: z.string() }),
});

export const ExpectedHelpOfferExpiredResults = ExpectedCommonResults.extend({
  author: z.object({
    user: z.object({ first_name_possessive: z.string() }),
  }),
});

export const ExpectedHelpOfferAcceptedResults = ExpectedCommonResults.extend({
  author: z.object({
    user: z.object({ first_name_possessive: z.string() }),
  }),
  category: z.object({ name: z.string() }),
});

export const ExpectedHelpOfferMarkedCompleteResults =
  ExpectedCommonResults.extend({
    author: z.object({
      user: z.object({ first_name: z.string() }),
    }),
  });

export const ExpectedHelpOfferRevisionRequestedResults =
  ExpectedCommonResults.extend({
    author: z.object({
      user: z.object({ first_name: z.string() }),
    }),
  });

export const ExpectedHelpOfferCompletedResults = ExpectedCommonResults.extend({
  author: z.object({
    id: z.number(),
    user: z.object({ first_name: z.string() }),
  }),
  refund_issued: z.boolean(),
  completed_by: z.number().nullable(),
});

export const ExpectedHelpOfferRefundRequestedResults =
  ExpectedCommonResults.extend({
    author: z.object({
      user: z.object({ first_name: z.string() }),
    }),
  });

export const ExpectedHelpOfferRefundRequestCancelledResults =
  ExpectedCommonResults;

export const ExpectedHelpOfferRefundIssuedResults =
  ExpectedCommonResults.extend({
    author: z.object({
      user: z.object({ first_name: z.string() }),
    }),
  });

export const ExpectedHelpOfferReviewSentResults = ExpectedCommonResults.extend({
  author: z.object({
    user: z.object({
      first_name: z.string(),
      first_name_possessive: z.string(),
      profile_url: z.string(),
    }),
  }),
  receiver: z.object({
    id: z.number(),
    user: z.object({
      id: z.number(),
      first_name: z.string(),
      first_name_possessive: z.string(),
    }),
  }),
});

export type RoomMessagesPaginatedResult = Pick<
  PaginatedResult<Message>,
  "results" | "next" | "previous"
>;

type BroadcastType = EnumType<typeof ENUMS.BroadcastType>;

export type MessengerEvent<TB extends BroadcastType = BroadcastType> = {
  broadcast_type: TB;
};

export type MessageSpamStatusEvent = MessengerEvent<
  typeof ENUMS.BroadcastType.MESSAGE_SPAM_STATUS
> & {
  message_id: number;
  room_id: number;
  is_marked_as_spam: boolean;
};

export type MessengerMessageEvent = MessengerEvent<
  typeof ENUMS.BroadcastType.MESSAGE
> & {
  message: UserMessage;
};

export type CalendarLinkPromptShowEvent = MessengerEvent<
  typeof ENUMS.BroadcastType.CALENDAR_LINK_PROMPT_SHOWN
> & {
  room_id: number;
};

export type MessageMarkAsReacEvent = MessengerEvent<
  typeof ENUMS.BroadcastType.MESSAGE_MARK_AS_READ
> & {
  room_id: number;
  messages_count: number;
};

export type HelpOfferUpdatedEvent = MessengerEvent<
  typeof ENUMS.BroadcastType.HELP_OFFER_UPDATED
> & {
  data?: HelpOffer & { message_id: number };
};

export type RoomMessageEvent =
  | MessageSpamStatusEvent
  | MessengerMessageEvent
  | CalendarLinkPromptShowEvent
  | MessageMarkAsReacEvent
  | HelpOfferUpdatedEvent;

export type GetRoomMessagesReturnType = RoomMessagesPaginatedResult;

export type GetRoomMessagesParamsType = {
  room?: number;
  message_content?: string;
  optimistic_id?: string;
  cursor?: string;
};

export type GetUnreadMessagesCountReturnType = {
  unread_messages_count: number;
};

export type GetRoomListReturnType = PaginatedResult<MessageRoom>;

export type GetRoomListParamsType = {
  name?: string;
  page?: number;
};

export type GetRoomReturnType = MessageRoom;

export type GetRoomParamType = {
  id: number;
};

export const isMessageEvent = (event: RoomMessageEvent) => {
  return (
    typeGuard<object, { message: UserMessage }>(event, "message") &&
    !!event.message
  );
};

export const shouldDisplaySystemMessageComponent = (
  message: Message,
): message is SystemMessageToDisplayInSystemMessageComponent => {
  return !!message.system_message_target;
};

export const isUserMessage = (message: Message): message is UserMessage => {
  return (
    !message.system_message_target &&
    message.message_type === ENUMS.MessageType.USER_MESSAGE
  );
};
export type HelpOfferStatusChanged = {
  help_offer_id: number;
  room_id: number;
  message_id: number;
  post_id?: number;
  budget?: string;
  reported?: boolean;
  marked_complete?: boolean;
  review_completed?: boolean;
  onramp_started?: boolean;
  refund_issued?: boolean;
  refund_requested?: boolean;
  status: EnumType<typeof ENUMS.HelpOfferStatus>;
};

export type MessageAuthor = Pick<
  User,
  | "id"
  | "first_name"
  | "last_name"
  | "public_name"
  | "gravatar"
  | "freelancer_approved"
  | "profile_url"
  | "account_type"
  | "title"
  | "created"
  | "has_avatar_set"
  | "avatar"
  | "avatar_thumbnail"
>;

export type MessageRoom = {
  id: number;
  created: string;
  participants: Participant[];
  interlocutors: number[];
  content_type: string;
  name: string;
  pending_help_offers_count: number | null;
  active_help_offers_count: number | null;
  unread_message_count: number;
  should_show_calendar_link_prompt: boolean;
  show_contact_support: boolean | null;
  is_last_message_spam: boolean;
  last_message_id: number | null;
  last_message_content: string;
  last_message_timestamp: string | null;
  last_message_author_id: number;
  is_talent_hired_by_client: boolean | null;
};

type InitiatorId = Participant["id"];
type InterlocutorId = Participant["id"];

export type MessageRoomContext = {
  participants: [InitiatorId, InterlocutorId];
  initiated_by_help_offer_request?: boolean;
};

export type GetOrCreateMessageRoomParams = {
  context: MessageRoomContext;
  jobId?: number;
  contentType?: EnumType<typeof ENUMS.RoomTypeContentType>;
};

export type Participant = Pick<
  User,
  | "id"
  | "first_name"
  | "last_name"
  | "public_name"
  | "gravatar"
  | "freelancer_approved"
  | "profile_url"
  | "account_type"
  | "title"
  | "created"
  | "has_avatar_set"
  | "avatar"
  | "avatar_thumbnail"
  | "location"
  | "timezone_abbreviation"
  | "timezone_offset"
>;

export type ReportUserMessageQueryArgs = ReportUserQueryArgs & {
  room?: number;
};

export type ReportUserQueryArgs = {
  id: number;
  reason: string;
  message_to_moderator?: string;
};

export type ReportUserServerResponse = {
  reason: string;
  message?: string;
  message_to_moderator?: string;
};

export type Author = {
  id: number;
  first_name: string;
  last_name: string;
  public_name: string;
  gravatar: string;
  freelancer_approved: boolean;
  agency: number;
  profile_url: string;
  account_type: string;
  title: string;
  created: string;
  has_agency_avatar_set: boolean;
  has_avatar_set: boolean;
  avatar: string;
  avatar_thumbnail: string;
};

export type MessageFileAttachmentData = {
  fileId: string;
  name: string;
  size: number;
  isImage: boolean;
  isLoading: boolean;
  id?: number;
  attachment?: Omit<Attachment, "thumbnail">;
};

export type UnmarkMessageAsSpamQueryArgs = {
  messageId: number;
  room: number;
};
