import React from "react";
import type { Control, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import dayjs from "dayjs";

import type { DatePopoverProps } from "@hexocean/braintrust-ui-components";
import { DatePopover } from "@hexocean/braintrust-ui-components";

export type RhfDatePopoverPickerFieldProps<T extends FieldValues> = Omit<
  DatePopoverProps,
  "value" | "onChange"
> &
  UseControllerProps<T> & {
    id: string;
    label: DatePopoverProps["label"];
    control: Control<T>;
  };

export const RhfDatePopoverField = <T extends FieldValues>({
  id,
  disabled,
  ...props
}: RhfDatePopoverPickerFieldProps<T>) => {
  const { field } = useController(props);

  return (
    <DatePopover
      value={field.value ? dayjs.utc(field.value) : null}
      onChange={(value) => {
        field.onChange(value ? value.format("YYYY-MM-DD") : null);
      }}
      onAccept={() => {
        field.onBlur(); // mark as touched
      }}
      disabled={disabled}
      {...props}
    />
  );
};
