import type { FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import type { RhfSliderProps } from "@js/rhf/fields/slider/slider";
import { RhfSlider } from "@js/rhf/fields/slider/slider";

export const HOURS_PER_WEEK_MIN_VALUE = 5;
export const HOURS_PER_WEEK_MAX_VALUE = 40;

export const RhfHoursPerWeekSlider = <T extends FieldValues>({
  showErrors,
  ...props
}: RhfSliderProps<T> & { showErrors?: boolean }) => {
  const { fieldState } = useController(props);
  return (
    <>
      <RhfSlider
        size="small"
        color="white"
        showMinMaxLabel
        marks
        step={5}
        min={HOURS_PER_WEEK_MIN_VALUE}
        max={HOURS_PER_WEEK_MAX_VALUE}
        {...props}
      />
      {showErrors && fieldState.error?.message && (
        <Typography mb={2} component="p" variant="paragraph" error>
          {fieldState.error.message}
        </Typography>
      )}
    </>
  );
};
