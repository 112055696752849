import type { ApplicationStatusWithTooltipProps } from "@js/apps/jobs/components/application-status/index";
import { Date } from "@js/components/date";
import { assertUnreachable } from "@js/utils";
import { DateFormats } from "@js/utils/date/types";

type ColorVariant = {
  bgColor: string;
  textColor: string;
};

type JobStatusesWithPillComponent = EnumType<
  Omit<typeof ENUMS.JobFreelancerStatus, "ACCEPTING_APPLICATIONS">
>;

export const getApplicationStatusColorVariant = (
  status: JobStatusesWithPillComponent,
): ColorVariant => {
  switch (status) {
    case ENUMS.JobFreelancerStatus.INVITED_BY_MATCHER:
    case ENUMS.JobFreelancerStatus.INVITED_BY_CLIENT:
      return {
        bgColor: "var(--medium-violet)",
        textColor: "var(--dark-violet)",
      };
    case ENUMS.JobFreelancerStatus.IN_REVIEW:
    case ENUMS.JobFreelancerStatus.PENDING_APPROVAL:
    case ENUMS.JobFreelancerStatus.APPLICATION_SENT:
    case ENUMS.JobFreelancerStatus.JOB_FILLED:
    case ENUMS.JobFreelancerStatus.JOB_ON_HOLD:
    case ENUMS.JobFreelancerStatus.JOB_CLOSED:
      return {
        bgColor: "var(--soft-grey)",
        textColor: "var(--dark-teal)",
      };
    case ENUMS.JobFreelancerStatus.LIVE_INTERVIEWING:
    case ENUMS.JobFreelancerStatus.AI_INTERVIEWING:
    case ENUMS.JobFreelancerStatus.IN_REVIEW_AFTER_AI_INTERVIEW:
      return {
        bgColor: "var(--medium-teal)",
        textColor: "var(--dark-teal)",
      };
    case ENUMS.JobFreelancerStatus.NEW_OFFER:
    case ENUMS.JobFreelancerStatus.UPDATED_OFFER:
    case ENUMS.JobFreelancerStatus.OFFER_EXTENDED:
    case ENUMS.JobFreelancerStatus.OFFER_ACCEPTED:
      return {
        bgColor: "var(--positive-3)",
        textColor: "var(--positive-1)",
      };
    case ENUMS.JobFreelancerStatus.APPLICATION_REJECTED:
      return {
        bgColor: "var(--medium-red)",
        textColor: "var(--dark-red)",
      };
    default: {
      assertUnreachable(status);
      return {
        bgColor: "var(--soft-grey)",
        textColor: "var(--dark-teal)",
      };
    }
  }
};

const APPLICATION_STATUS_DATE_FORMAT = DateFormats["Jan 1"];

export const getApplicationTooltipText = ({
  status,
  appliedDate,
  offerCreatedDate,
  offerAcceptedDate,
  offerUpdatedDate,
}: Omit<ApplicationStatusWithTooltipProps, "status"> & {
  status: JobStatusesWithPillComponent;
}) => {
  switch (status) {
    case ENUMS.JobFreelancerStatus.INVITED_BY_MATCHER:
    case ENUMS.JobFreelancerStatus.INVITED_BY_CLIENT:
      return null;
    case ENUMS.JobFreelancerStatus.PENDING_APPROVAL:
      if (!appliedDate) return null;
      return (
        <>
          You’ve applied on{" "}
          <Date date={appliedDate} format={APPLICATION_STATUS_DATE_FORMAT} />,
          but your application wasn’t sent to the client because your account is
          pending approval.
        </>
      );
    case ENUMS.JobFreelancerStatus.APPLICATION_SENT:
      if (!appliedDate) return null;
      return (
        <>
          You’ve applied on{" "}
          <Date date={appliedDate} format={APPLICATION_STATUS_DATE_FORMAT} />.
        </>
      );
    case ENUMS.JobFreelancerStatus.IN_REVIEW:
      if (!appliedDate) return null;
      return (
        <>
          You’ve applied on{" "}
          <Date date={appliedDate} format={APPLICATION_STATUS_DATE_FORMAT} />{" "}
          and your application is currently in review.
        </>
      );
    case ENUMS.JobFreelancerStatus.IN_REVIEW_AFTER_AI_INTERVIEW:
      return "Your AI interview is being reviewed";
    case ENUMS.JobFreelancerStatus.LIVE_INTERVIEWING:
      return "You’ve been invited to schedule a live video interview. The hiring manager has provided their scheduling link to get started.";
    case ENUMS.JobFreelancerStatus.AI_INTERVIEWING:
      return "You’ve been invited to an AI interview. You can start it any time.";
    case ENUMS.JobFreelancerStatus.NEW_OFFER:
    case ENUMS.JobFreelancerStatus.UPDATED_OFFER:
      if (!offerCreatedDate) return null;
      return (
        <>
          New offer sent{" "}
          <Date
            date={offerCreatedDate}
            format={APPLICATION_STATUS_DATE_FORMAT}
          />
          .
        </>
      );
    case ENUMS.JobFreelancerStatus.OFFER_EXTENDED:
      if (!offerUpdatedDate) return null;
      return (
        <>
          Offer updated{" "}
          <Date
            date={offerUpdatedDate}
            format={APPLICATION_STATUS_DATE_FORMAT}
          />
          .
        </>
      );
    case ENUMS.JobFreelancerStatus.OFFER_ACCEPTED:
      if (!offerAcceptedDate) return null;
      return (
        <>
          Offer accepted{" "}
          <Date
            date={offerAcceptedDate}
            format={APPLICATION_STATUS_DATE_FORMAT}
          />
          .
        </>
      );
    case ENUMS.JobFreelancerStatus.JOB_FILLED:
    case ENUMS.JobFreelancerStatus.JOB_ON_HOLD:
    case ENUMS.JobFreelancerStatus.JOB_CLOSED:
      return "This job is currently not accepting applications.";
    case ENUMS.JobFreelancerStatus.APPLICATION_REJECTED:
      return "You were not selected for this position. Continue applying!";

    default: {
      assertUnreachable(status);
    }
  }
};
