import { useLocation, useSearchParams } from "react-router-dom";

import { Button } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { openMakeOfferMessageModal } from "@js/apps/jobs/apps/bids";
import { getMakeOfferUrl } from "@js/apps/jobs/utils";
import { useGoBackHistory, useNavigate } from "@js/hooks";
import type { EmployerBid } from "@js/types/jobs";
import { isNotNullable } from "@js/utils";

import { EmployerOfferStatusWithJobTitle } from "../offer-status-with-job-title";

import { EmployerOfferCTAButtons } from "./employer-navigation-buttons";
import { OfferNavigation } from "./helpers";

const CONTRACT_HISTORY_PAGE_PARAMS = "contract_history_page";

type EmployerNavigationProps = {
  jobId: number;
  jobTitle: string;
  offerStatus: EnumType<typeof ENUMS.OfferStatus> | null;
  offerId?: number;
  interlocutor: number;
  previewPage?: boolean;
  bid?: EmployerBid;
};

export const EmployerNavigation = ({
  jobId,
  jobTitle,
  offerStatus,
  offerId,
  interlocutor,
  previewPage,
  bid,
}: EmployerNavigationProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const goBack = useGoBackHistory();
  const isMobile = useMediaQuery("sm");
  const [params] = useSearchParams();
  const isContractHistoryPageParams = params.get(CONTRACT_HISTORY_PAGE_PARAMS);

  const handleGoBackClick = () => {
    const lastPage = new URLSearchParams(location.state?.prevSearch).get(
      "page",
    );
    const pagination = lastPage ? `?page=${lastPage}` : "";

    if (bid && !offerStatus) {
      const makeOfferUrl = getMakeOfferUrl({ jobId, bidId: bid.id });

      openMakeOfferMessageModal({
        bid,
        nextUrl: makeOfferUrl,
      });
      // to prevent redirection to a link with open drawer,
      // which is fully fetched after opening of the modal and takes focus from it
      navigate(`/jobs/${jobId}/proposals/${pagination}`);
    } else {
      if (isContractHistoryPageParams) {
        navigate(`/jobs/${jobId}/proposals/${pagination}`);
        return;
      }
      goBack(`/jobs/${jobId}/proposals/${pagination}`);
    }
  };

  const showElements = isMobile ? isNotNullable(offerStatus) : true;

  return (
    <OfferNavigation
      leftSocket={
        showElements ? (
          <Button
            variant="transparent"
            shape="squared"
            onClick={handleGoBackClick}
            sx={{
              display: "grid",
              placeItems: "center",
            }}
            startIcon={<CloseIcon />}
          >
            Go back
          </Button>
        ) : undefined
      }
      centerSocket={
        <EmployerOfferStatusWithJobTitle
          jobId={jobId}
          jobTitle={jobTitle}
          offerStatus={offerStatus}
          showTitle={showElements}
        />
      }
      rightSocket={
        <EmployerOfferCTAButtons
          jobId={jobId}
          offerId={offerId}
          offerStatus={offerStatus}
          interlocutor={interlocutor}
          previewPage={previewPage}
        />
      }
    />
  );
};
